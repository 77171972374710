var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostPortalMemberStandardProgress } from '../components/BoostPortalMemberStandardProgress';
import { BoostButton } from '../components/common/BoostButton';
import { BoostPortalChooseDepartment } from '../components/BoostPortalChooseDepartment';
import { BoostPortalChooseMembership } from '../components/BoostPortalChooseMembership';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { BoostStatusContainer } from '../components/common/BoostStatusContainer';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { BoostStepIndicator } from '../components/common/BoostStepIndicator';
import { BoostPortalPaymentOptions } from '../components/common/BoostPortalPaymentOptions';
import { DocumentViewer } from 'common-uicomponents/components/DocumentViewer';
import { BoostPortalPaymentSuccess } from '../components/common/BoostPortalPaymentSuccess';
import { Status } from '../enums/VippsStatus';
import { Callback } from '../enums/Callback';
import { BoostPortalNavigationSteps } from '../enums/BoostPortalNavigationSteps';
import { BoostPortalTokenContainer } from 'boost-services/BoostPortalTokenContainer';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
import { BoostPortalPaymentStatusHelper, PaymentStatus } from '../helpers/BoostPortalPaymentStatusHelper';
let BoostPortalRegisterMemberViewWithPayment = class BoostPortalRegisterMemberViewWithPayment extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.currentStep = BoostPortalNavigationSteps.DEPARTMENT;
        this.memberIsUnderaged = false;
        this.meetsMinimumAge = true;
        this.isSponsorSetup = false;
        this.memberExists = false;
        this.shortTerm = false;
        this.noDirectDebit = false;
        this.isHiddenMembership = false;
        this.hasTestMembership = false;
    }
    static get is() {
        return 'boost-portal-register-member-view-with-payment';
    }
    static get styles() {
        return css `
            :host {
                width: 100%;
                padding: 1rem;
            }

            h3 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            a:link,
            a:visited,
            a:active {
                text-decoration: underline;
                color: var(--portalLink, #2a9ba3);
            }

            a:hover {
                text-decoration: none;
            }

            .submit {
                padding-top: 25px;
            }

            @media only screen and (max-width: 800px) {
                .submit {
                    padding-top: 0px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-portal-header
                .disabled="${this.callback === Callback.NETS || this.callback === Callback.VIPPS}"
                .viewLoginButton="${false}"
            ></boost-portal-header>

            <boost-step-indicator
                .currentStep="${this.currentStep}"
                .steps="${this._getSteps(this.currentStep)}"
                @currentStep-changed="${(event) => {
            this.currentStep = event.detail.currentStep;
        }}"
            ></boost-step-indicator>
            ${this._getViewForCurrentStep(this.currentStep)}
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this.popCallback = () => this.getUrlParameters();
        window.addEventListener('popstate', this.popCallback);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (!this.popCallback) {
            return;
        }
        window.removeEventListener('popstate', this.popCallback);
    }
    _callbackObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.callback == Callback.NETS) {
                if (this.netsStatus == Status.FAILED) {
                    this._routeToDirectDebit();
                    TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.AvtalegiroSigningFailed);
                    return;
                }
                if (this.noDirectDebit && !((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.invoice.rest)) {
                    this.currentStep = BoostPortalNavigationSteps.PAYMENT;
                    return;
                }
                if (this.invoice && !this.invoice.invoice.rest) {
                    this.currentStep = BoostPortalNavigationSteps.SUCCESS;
                    TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.AvtalegiroSigningCompleted);
                    return;
                }
                this.currentStep = BoostPortalNavigationSteps.PAYMENT;
            }
            if (this.callback == Callback.VIPPS) {
                const paymentStatus = yield BoostPortalPaymentStatusHelper.getPaymentStatus();
                if (paymentStatus === PaymentStatus.PAYMENTSUCCESS) {
                    this.currentStep = BoostPortalNavigationSteps.SUCCESS;
                    TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.VippsPaymentComplete);
                    return;
                }
                this.currentStep = BoostPortalNavigationSteps.PAYMENT;
            }
            this._currentStepObserver(this.currentStep);
        });
    }
    getUrlParameters() {
        const urlParameterData = history.state;
        if (!urlParameterData) {
            return;
        }
        const { callback, invoiceId, vippsSetup, netsStatus, centerShort, shortTerm, noDirectDebit } = urlParameterData;
        if (invoiceId) {
            this.invoiceId = invoiceId;
        }
        if (vippsSetup) {
            this.isVippsSetup = true;
        }
        if (callback) {
            this.callback = callback;
        }
        if (netsStatus) {
            this.netsStatus = netsStatus;
        }
        if (centerShort) {
            this.centerShort = centerShort;
        }
        if (shortTerm) {
            this.shortTerm = shortTerm;
        }
        if (noDirectDebit) {
            this.noDirectDebit = noDirectDebit;
        }
    }
    _tokenObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            const sponsorSetupResult = yield MembersignupAPI.getV1BoostsignupSponsorsetup();
            if (isSuccessHTTP(sponsorSetupResult)) {
                this.isSponsorSetup = sponsorSetupResult.status;
            }
            else if (isErrorHTTP(sponsorSetupResult)) {
                SnackBar.pushError(sponsorSetupResult.error);
            }
        });
    }
    _memberHandler(event) {
        if (this.session && this.loggedInProfile && this.loggedInProfile.memberid) {
            this._updateMember(event);
        }
        else {
            this._createMember(event);
        }
    }
    _updateMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!this.memberInformation ||
                !((_a = this.loggedInProfile) === null || _a === void 0 ? void 0 : _a.memberid) ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            const buttonElement = event.target;
            if (buttonElement.disabled) {
                return;
            }
            this.memberInformation.membershipId = this.selectedMembershipId;
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/member' +
                    '?callback=nets&shortterm=' +
                    this.shortTerm +
                    '&directdebit=' +
                    this.noDirectDebit;
            this.requestUpdate('memberInformation');
            this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
            buttonElement.setLoading(true);
            const updatedMemberResult = yield MembersignupAPI.putV1BoostsignupMemberUsingMemberId(this.loggedInProfile.memberid, this.memberInformation);
            if (isSuccessHTTP(updatedMemberResult)) {
                this.memberInformation = Object.assign(Object.assign({}, this.memberInformation), updatedMemberResult);
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                const createMembershipResult = yield MembersignupAPI.postV1BoostsignupMemberContractUsingMemberId(updatedMemberResult.memberId, {
                    membershipId: this.selectedMembershipId,
                    departmentId: this.selectedDepartmentId,
                    netsCallbackUrl: this.memberInformation.netsCallbackUrl,
                });
                if (isSuccessHTTP(createMembershipResult)) {
                    this.memberInformation.invoiceId = createMembershipResult.invoiceId;
                    this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                    if (this.noDirectDebit) {
                        this.currentStep = BoostPortalNavigationSteps.PAYMENT;
                        this.callback = Callback.NETS;
                    }
                    else {
                        // TODO denne burde kanskje vært awaited, for å være sikker
                        // på at tracking er fullført før man sendes avgårde...
                        // men vi fikk noe problemer, så vi deaktiverer det enn så lenge.
                        TrackingFactorySingleton.asyncTracker((tracker) => tracker.completedEvent(TrackingEvent.BankIDSigningStarted));
                        this.memberInformation.link_to_nets = createMembershipResult.link_to_nets;
                        window.location.replace(this.memberInformation.link_to_nets);
                    }
                }
                else if (isErrorHTTP(createMembershipResult)) {
                    SnackBar.pushError(createMembershipResult.error);
                }
            }
            else if (isErrorHTTP(updatedMemberResult)) {
                SnackBar.pushError(updatedMemberResult.error);
            }
            buttonElement.setLoading(false);
        });
    }
    _createMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = event.target;
            this.memberExists = false;
            if (!this.memberInformation ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            this.memberInformation.membershipId = this.selectedMembershipId;
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/member' +
                    '?callback=nets&shortterm=' +
                    this.shortTerm +
                    '&directdebit=' +
                    this.noDirectDebit;
            this.requestUpdate('memberInformation');
            buttonElement.setLoading(true);
            const createdMemberResult = yield MembersignupAPI.postV1BoostsignupMember(this.memberInformation);
            if (isErrorHTTP(createdMemberResult)) {
                if (createdMemberResult.error.http_status === 409) {
                    this.memberExists = true;
                }
                else {
                    SnackBar.pushError(createdMemberResult.error);
                }
            }
            else if (isSuccessHTTP(createdMemberResult)) {
                this.memberInformation = createdMemberResult;
                // TODO denne burde kanskje vært awaited, for å være sikker
                // på at tracking er fullført før man sendes avgårde...
                // men vi fikk noe problemer, så vi deaktiverer det enn så lenge.
                TrackingFactorySingleton.asyncTracker((tracker) => tracker.completedEvent(TrackingEvent.PersonalInformationFilled));
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                if (this.noDirectDebit) {
                    this.currentStep = BoostPortalNavigationSteps.PAYMENT;
                    this.callback = Callback.NETS;
                }
                else {
                    window.location.replace(this.memberInformation.link_to_nets);
                }
            }
            buttonElement.setLoading(false);
        });
    }
    _currentStepObserver(oldValue) {
        if (this.currentStep === BoostPortalNavigationSteps.DIRECTDEBIT ||
            this.currentStep === BoostPortalNavigationSteps.SUCCESS ||
            this.currentStep === BoostPortalNavigationSteps.INFORMATION ||
            this.currentStep === BoostPortalNavigationSteps.PAYMENT) {
            this.currentStep = oldValue;
        }
        else if (this.currentStep >= oldValue) {
            this.currentStep = oldValue;
        }
    }
    _getSteps(currentStep) {
        const steps = [
            {
                title: 'Senter',
                isActive: currentStep === BoostPortalNavigationSteps.DEPARTMENT,
                subtitle: '',
            },
            {
                title: 'Personalia',
                isActive: currentStep === BoostPortalNavigationSteps.INFORMATION,
                subtitle: '',
            },
            {
                title: 'Signering',
                isActive: currentStep === BoostPortalNavigationSteps.DIRECTDEBIT,
                subtitle: '',
            },
            {
                title: 'Betaling',
                isActive: currentStep === BoostPortalNavigationSteps.PAYMENT,
                subtitle: '',
            },
            {
                title: 'Fullført',
                isActive: currentStep === BoostPortalNavigationSteps.SUCCESS,
                subtitle: '',
            },
        ];
        const shortTermMembershipSteps = [
            {
                title: 'Senter',
                isActive: currentStep === BoostPortalNavigationSteps.DEPARTMENT,
                subtitle: '',
            },
            {
                title: 'Personalia',
                isActive: currentStep === BoostPortalNavigationSteps.INFORMATION,
                subtitle: '',
            },
            {
                title: 'Betaling',
                isActive: currentStep === BoostPortalNavigationSteps.PAYMENT,
                subtitle: '',
            },
            {
                title: 'Fullført',
                isActive: currentStep === BoostPortalNavigationSteps.SUCCESS,
                subtitle: '',
            },
        ];
        if (this.noDirectDebit) {
            return shortTermMembershipSteps;
        }
        return steps;
    }
    _isValidMemberInformation() {
        if (!this.memberInformation || !this.boostPortalPersonalInformation) {
            return false;
        }
        return this.boostPortalPersonalInformation.validateFields();
    }
    _routeToPrivateSponsor() {
        window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/member/private');
        window.dispatchEvent(new PopStateEvent('popstate'));
    }
    _routeToDirectDebit() {
        window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/avtalegiro');
        window.dispatchEvent(new PopStateEvent('popstate'));
    }
    _getViewForCurrentStep(currentStep) {
        switch (currentStep) {
            case BoostPortalNavigationSteps.DEPARTMENT:
                return this._renderDepartmentMembershipOptions();
            case BoostPortalNavigationSteps.INFORMATION:
                return this._renderPersonalInformation();
            case BoostPortalNavigationSteps.PAYMENT:
                return this._renderPaymentOptions();
            case BoostPortalNavigationSteps.SUCCESS:
                return this._renderPaymentSuccess();
        }
    }
    _renderDepartmentMembershipOptions() {
        return html `
            <boost-portal-choose-department
                .disabled="${this.isHiddenMembership}"
            ></boost-portal-choose-department>

            ${this.selectedDepartmentId
            ? html ` <boost-portal-choose-membership
                      @hiddenMembership-changed="${(event) => (this.isHiddenMembership = event.detail.value)}"
                  ></boost-portal-choose-membership>`
            : html `<div style="padding-bottom:16px;"></div>`}

            <div class="submit">
                <boost-button
                    type="submit"
                    name="Gå videre"
                    @click="${() => this._nextStep()}"
                    ?disabled="${!this.selectedDepartmentId ||
            !this.selectedMembershipId ||
            (this.loggedInProfile && Boolean(this.hasTestMembership))}"
                >
                </boost-button>
            </div>
        `;
    }
    _nextStep() {
        var _a, _b, _c;
        if (!this.selectedDepartmentId || !this.selectedMembershipId) {
            return;
        }
        if (this.loggedInProfile && this.hasTestMembership) {
            return;
        }
        const membershipId = this.selectedMembershipId;
        const membershipName = (_c = (_b = (_a = this.memberships) === null || _a === void 0 ? void 0 : _a.find((membership) => membership.id == membershipId)) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : 'Medlemskap med ukjent navn ' + membershipId;
        TrackingFactorySingleton.asyncTracker((tracker) => tracker.completedEvent(TrackingEvent.SelectedMembership, { membershipId, membershipName }));
        this.currentStep = BoostPortalNavigationSteps.INFORMATION;
    }
    _renderPaymentOptions() {
        return html `<boost-portal-payment-options
            .invoice="${this.invoice}"
            .noDirectDebit="${this.noDirectDebit}"
            .selectedMembership="${this.selectedMembership}"
            .callback="${this.callback}"
            .shortTerm="${this.shortTerm}"
            @currentStep-changed="${(event) => (this.currentStep = event.detail.currentStep)}"
        ></boost-portal-payment-options>`;
    }
    _renderPaymentSuccess() {
        return html `<boost-portal-payment-success></boost-portal-payment-success>`;
    }
    _renderPersonalInformation() {
        return html `<boost-portal-personal-information
                id="boostPortalPersonalInformation"
                @memberPhoneNumber-changed="${() => (this.memberExists = false)}"
                @memberIsUnderaged-changed="${(event) => (this.memberIsUnderaged = event.detail.memberIsUnderaged)}"
                @meetsMinimumAge="${(event) => {
            this.meetsMinimumAge = event.detail.value;
            this.requestUpdate('meetsMinimumAge');
        }}"
            ></boost-portal-personal-information>

            ${this.memberExists
            ? html `
                      <boost-status-container type="info">
                          Du har vært medlem tidligere.
                          <a href="${router.baseUrl}${this.centerShort}/login">Trykk her</a> for å inngå nytt
                          medlemskap.
                      </boost-status-container>
                  `
            : this._renderMemberAge()}`;
    }
    _renderMemberAge() {
        var _a, _b, _c, _d;
        if (!this.meetsMinimumAge) {
            return html `<boost-status-container type="info">
                For å registrere deg hos oss må du være minst ${(_a = this.center) === null || _a === void 0 ? void 0 : _a.minimum_age} år gammel. Ta
                kontakt med senteret hvis du lurer på noe.
            </boost-status-container>`;
        }
        return html ` ${!this.memberIsUnderaged
            ? html `
                  <div class="next-button">
                      <div style="padding:25px 10px 10px 10px">
                          ${!((_b = this.selectedMembership) === null || _b === void 0 ? void 0 : _b.no_direct_debit)
                ? html ` <span
                                    >Du er snart ferdig, trykk Bestill og signer med BankID for å godkjenne og
                                    lagre dine personopplysninger</span
                                >`
                : html `<span
                                    >Gå videre for å lagre dine personopplysninger, betale og aktivere ditt
                                    medlemskap</span
                                >`}
                      </div>
                      ${!((_c = this.selectedMembership) === null || _c === void 0 ? void 0 : _c.no_direct_debit)
                ? html ` <boost-button
                                type="submit"
                                name="Bestill og signer med BankID"
                                @click="${(event) => this._memberHandler(event)}"
                            >
                            </boost-button>`
                : html `<boost-button
                                type="submit"
                                name="Gå videre til betaling"
                                @click="${(event) => this._memberHandler(event)}"
                            >
                            </boost-button>`}
                  </div>
              `
            : html `
                  ${this.isSponsorSetup && !((_d = this.selectedMembership) === null || _d === void 0 ? void 0 : _d.no_direct_debit)
                ? html `<boost-status-container type="info">
                                Personer under 18 år må betales for av en annen myndig person.
                            </boost-status-container>

                            <boost-button
                                type="submit"
                                name="Klikk her for å gå til Bli medlem der andre skal betale for deg"
                                @click="${this._routeToPrivateSponsor}"
                                width-full
                            ></boost-button>`
                : html `<boost-status-container type="info">
                            Personer under 18 år må betales for av en annen myndig person. Ta kontakt med
                            senteret.
                        </boost-status-container> `}
              `}`;
    }
    _loadInvoice() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.invoiceId) {
                return;
            }
            this.dispatch(BoostPortalStore.Actions.SetToken(yield BoostPortalTokenContainer.token.getValue()));
            const invoiceRequest = yield MembersignupAPI.getV1BoostsignupInvoiceUsingInvoiceId(this.invoiceId);
            if (isSuccessHTTP(invoiceRequest)) {
                this.invoice = invoiceRequest;
            }
        });
    }
    _membershipsObserver() {
        if (this.memberships && this.selectedMembershipId) {
            this.selectedMembership = this.memberships.find((membership) => membership.id === this.selectedMembershipId);
        }
    }
    _memberInformationObserver() {
        var _a, _b;
        if ((_a = this.selectedMembership) === null || _a === void 0 ? void 0 : _a.no_direct_debit) {
            this.invoiceId = ((_b = this.memberInformation) === null || _b === void 0 ? void 0 : _b.invoiceId) ? this.memberInformation.invoiceId : 0;
        }
    }
    _selectedMembershipObserver() {
        if (this.selectedMembership) {
            this.shortTerm = this.selectedMembership.temporary;
            this.noDirectDebit = this.selectedMembership.no_direct_debit;
            this.hasTestMembership = this.selectedMembership.test_membership;
        }
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentStep':
                    this._currentStepObserver(oldValue);
                    break;
                case 'token':
                    this._tokenObserver();
                    break;
                case 'callback':
                    this._callbackObserver();
                    break;
                case 'invoiceId':
                    this._loadInvoice();
                    break;
                case 'memberships':
                case 'selectedMembershipId':
                    this._membershipsObserver();
                    break;
                case 'memberInformation':
                    this._memberInformationObserver();
                    break;
                case 'selectedMembership':
                    this._selectedMembershipObserver();
                    break;
                case 'invoice':
                    this._callbackObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortalRegisterMemberViewWithPayment._deps = [
    BoostButton,
    BoostStepIndicator,
    BoostPortalChooseDepartment,
    BoostPortalChooseMembership,
    BoostPortalPersonalInformation,
    BoostPortalMemberStandardProgress,
    BoostStatusContainer,
    BoostPortalHeader,
    BoostPortalPaymentOptions,
    DocumentViewer,
    BoostPortalPaymentSuccess,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberViewWithPayment.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberViewWithPayment.prototype, "center", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberViewWithPayment.prototype, "currentStep", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberViewWithPayment.prototype, "memberInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "memberIsUnderaged", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "meetsMinimumAge", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberViewWithPayment.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberViewWithPayment.prototype, "selectedMembershipId", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "isSponsorSetup", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('token')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberViewWithPayment.prototype, "token", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberViewWithPayment.prototype, "session", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "memberExists", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberViewWithPayment.prototype, "loggedInProfile", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberships')),
    state(),
    __metadata("design:type", Array)
], BoostPortalRegisterMemberViewWithPayment.prototype, "memberships", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberViewWithPayment.prototype, "selectedMembership", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "isVippsSetup", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostPortalRegisterMemberViewWithPayment.prototype, "callback", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberViewWithPayment.prototype, "netsStatus", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberViewWithPayment.prototype, "invoiceId", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "shortTerm", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "noDirectDebit", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberViewWithPayment.prototype, "invoice", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "isHiddenMembership", void 0);
__decorate([
    query('#boostPortalPersonalInformation'),
    __metadata("design:type", BoostPortalPersonalInformation)
], BoostPortalRegisterMemberViewWithPayment.prototype, "boostPortalPersonalInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberViewWithPayment.prototype, "hasTestMembership", void 0);
BoostPortalRegisterMemberViewWithPayment = __decorate([
    customElement(BoostPortalRegisterMemberViewWithPayment.is)
], BoostPortalRegisterMemberViewWithPayment);
export { BoostPortalRegisterMemberViewWithPayment };
